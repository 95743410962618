import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'

import styles from 'site/styles'
import 'site/stylesheets/site.sass'

import 'site/images/app-preview/dashboard.png'
import 'site/images/app-preview/dashboard@2x.png'
import 'site/images/app-preview/mobile-screen.png'
import 'site/images/app-preview/mobile-screen@2x.png'

import 'site/images/key-features/backgrounds/background-key-features-bottom-left.png'
import 'site/images/key-features/backgrounds/background-key-features-bottom-left@2x.png'
import 'site/images/key-features/backgrounds/background-key-features-bottom-right.png'
import 'site/images/key-features/backgrounds/background-key-features-bottom-right@2x.png'
import 'site/images/key-features/backgrounds/background-key-features-top-left.png'
import 'site/images/key-features/backgrounds/background-key-features-top-left@2x.png'

import 'site/images/key-features/screenshots/desktop-additional-integrations.png'
import 'site/images/key-features/screenshots/desktop-additional-integrations@2x.png'
import 'site/images/key-features/screenshots/desktop-advanced-cash-flow-analysis.png'
import 'site/images/key-features/screenshots/desktop-advanced-cash-flow-analysis@2x.png'
import 'site/images/key-features/screenshots/desktop-easy-invoicing.png'
import 'site/images/key-features/screenshots/desktop-easy-invoicing@2x.png'
import 'site/images/key-features/screenshots/desktop-holiday-work-schedules.png'
import 'site/images/key-features/screenshots/desktop-holiday-work-schedules@2x.png'
import 'site/images/key-features/screenshots/desktop-painless-time-tracking.png'
import 'site/images/key-features/screenshots/desktop-painless-time-tracking@2x.png'
import 'site/images/key-features/screenshots/desktop-profitability-forecasting.png'
import 'site/images/key-features/screenshots/desktop-profitability-forecasting@2x.png'
import 'site/images/key-features/screenshots/desktop-project-settlement.png'
import 'site/images/key-features/screenshots/desktop-project-settlement@2x.png'
import 'site/images/key-features/screenshots/desktop-project-stages-completion.png'
import 'site/images/key-features/screenshots/desktop-project-stages-completion@2x.png'
import 'site/images/key-features/screenshots/desktop-recurring-task-checklists.png'
import 'site/images/key-features/screenshots/desktop-recurring-task-checklists@2x.png'
import 'site/images/key-features/screenshots/mobile-additional-integrations.png'
import 'site/images/key-features/screenshots/mobile-additional-integrations@2x.png'
import 'site/images/key-features/screenshots/mobile-advanced-cash-flow-analysis.png'
import 'site/images/key-features/screenshots/mobile-advanced-cash-flow-analysis@2x.png'
import 'site/images/key-features/screenshots/mobile-easy-invoicing.png'
import 'site/images/key-features/screenshots/mobile-easy-invoicing@2x.png'
import 'site/images/key-features/screenshots/mobile-holiday-work-schedules.png'
import 'site/images/key-features/screenshots/mobile-holiday-work-schedules@2x.png'
import 'site/images/key-features/screenshots/mobile-painless-time-tracking.png'
import 'site/images/key-features/screenshots/mobile-painless-time-tracking@2x.png'
import 'site/images/key-features/screenshots/mobile-profitability-forecasting.png'
import 'site/images/key-features/screenshots/mobile-profitability-forecasting@2x.png'
import 'site/images/key-features/screenshots/mobile-project-settlement.png'
import 'site/images/key-features/screenshots/mobile-project-settlement@2x.png'
import 'site/images/key-features/screenshots/mobile-project-stages-completion.png'
import 'site/images/key-features/screenshots/mobile-project-stages-completion@2x.png'
import 'site/images/key-features/screenshots/mobile-recurring-task-checklists.png'
import 'site/images/key-features/screenshots/mobile-recurring-task-checklists@2x.png'

import 'site/images/call-to-action/call-to-action-tracking.png'
import 'site/images/call-to-action/call-to-action-tracking@2x.png'
import 'site/images/banners/backgrounds/background-manage-your-company-with-ease.png'
import 'site/images/banners/backgrounds/background-manage-your-company-with-ease@2x.png'

import 'site/images/svg/logos/ceod-logo-text-colors.svg'
import 'site/images/svg/logos/ceod-logo-text-white.svg'
import 'site/images/svg/logos/ceod-logo.svg'
import 'site/images/svg/crucial_data_orbs.svg'
import 'site/images/svg/footer-fanpage-circle.svg'

import Turbolinks from 'turbolinks'

Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  styles.init()
})
