export default {
  init () {
    this.initLazyImages()
  },
  initLazyImages () {
    const sections = document.querySelectorAll('.section')

    if (!sections.length) {
      return
    }

    const lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting) {
          return
        }

        const lazyImages = entry.target.querySelectorAll('img[data-lazy-src], source[data-lazy-srcset]')

        if (!lazyImages.length) {
          return
        }

        lazyImages.forEach((lazyImage) => {
          lazyImage.classList.remove('lazy-image-placeholder')

          if (lazyImage.dataset.lazySrc) {
            lazyImage.src = lazyImage.dataset.lazySrc
            lazyImage.removeAttribute('data-lazy-src')
          }

          if (lazyImage.dataset.lazySrcset) {
            lazyImage.srcset = lazyImage.dataset.lazySrcset
            lazyImage.removeAttribute('data-lazy-srcset')
          }
        })

        observer.unobserve(entry.target)
      })
    })

    sections.forEach(e => lazyImageObserver.observe(e))
  }
}
