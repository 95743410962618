const toggleSidebar = () => {
  document.getElementById('sidebar').classList.toggle('sidebar--active')

  const sidebarIcon = document.getElementById('sidebar__icon')
  if (sidebarIcon) {
    const hamburgerMenuIcon = sidebarIcon.firstChild
    hamburgerMenuIcon.classList.toggle('hamburger-menu-icon--opened')
  }
}

window.addEventListener('turbolinks:load', () => {
  const sidebarIcon = document.getElementById('sidebar__icon')
  if (sidebarIcon) {
    sidebarIcon.addEventListener('click', toggleSidebar)
  }
})
window.addEventListener('turbolinks:visit', () => {
  const sidebarIcon = document.getElementById('sidebar__icon')
  if (sidebarIcon) {
    sidebarIcon.removeEventListener('click', toggleSidebar)
  }
})
window.addEventListener('turbolinks:before-render', () => {
  if (
    document.getElementById('sidebar').classList.contains('sidebar--active')
  ) {
    toggleSidebar()
  }
})
