import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import './common/sidebar'
import './common/urls'
import './common/userLoggedOut'

require('@rails/activestorage').start()

Rails.start()
Turbolinks.start()
