import camelcaseKeysDeep from 'camelcase-keys-deep'
const entities = require('entities')

const parseUrls = () => {
  const getUrlsObject = urls => {
    try {
      return camelcaseKeysDeep(JSON.parse(entities.decodeHTML(urls)))
    } catch (e) {
      console.error(`Could not parse \`window.urls\`! ${window.urls}`)
      console.error(e)
      return {}
    }
  }

  const urls = getUrlsObject(window.urls)
  window.urls = urls
}
parseUrls()
